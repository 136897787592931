import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { ContactForm } from "@global";
import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { assets } from "@util/constants";
import Header from "@shared/header";
interface Props extends PageProps {
  data: Query;
}

const ContactPage = ({ data: { sanityContact } }: Props) => {
  if (!sanityContact) return null;
  const { seo } = sanityContact;

  return (
    <Wrapper>
      <Header isSolidHeader />
      <SEO seoData={seo} />
      <ContactForm />
    </Wrapper>
  );
};

export const query = graphql`
  query ContactQuery {
    sanityContact {
      seo {
        ...sanitySeo
      }
    }
  }
`;

export default ContactPage;


const Wrapper = styled.div`
  min-height: 100vh;
  background-size: cover;
  background-image: url(${assets.background});
  background-position: right;
`;